import type { ThemeOverride } from '@chakra-ui/react';
import { breakpoint as primitiveBreakpoint } from '@ifixit/primitives';

export const breakpoints: ThemeOverride['breakpoints'] = {
   sm: primitiveBreakpoint.sm,
   md: primitiveBreakpoint.md,
   mdPlus: '769px', // Chakra uses min-width queries, we need 'md/768px' + 1px
   lg: primitiveBreakpoint.lg,
   xl: primitiveBreakpoint.xl,
   '2xl': primitiveBreakpoint['2xl'],
};
